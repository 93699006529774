/* * {
  min-width: 0;
  min-height:0
} */

.alignCenter {
  text-align: center;
}

.bp5-checkbox > .bp5-control-indicator::before{
  visibility: hidden;
}
.bp5-checkbox{
  margin:0;
}

.uppercase {
  text-transform: uppercase;
}

.disabled {
  background-color: rgba(211, 216, 222, 0.5);
  color: rgba(95, 107, 124, 0.6);
}

.negative{
  background-color: #ffe6e6;
}

.lora{
  background-color: #ffffe0;
}

.selected {
  border: 1px solid rgba(125, 188, 255, 1);
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.5rem !important;
}

.mt-1 {
  margin-top: 0.5rem !important;
}

.mb-1{
  margin-bottom: 0.5rem !important;
}

.mb-2{
  margin-bottom: 1rem !important;
}

.py-1 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-2 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.m-1 {
  margin: 0.5rem !important;
}

.mx-1 {
  margin-left: 0.5rem !important;
  margin-right: 0.5rem !important;
}

.my-1 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.m-0 {
  margin: 0 !important;
}

.overflow {
  overflow: scroll;
}

.scrollable-dropdown {
  max-height: 50vh;
  overflow-x: hidden;
  overflow-y: auto;
}

.fullWidthChildren > * {
  width: 100%;
}

.fullWidth {
  width: 100%;
}

/* Overriding library */
.mosaic-window-body {
  overflow-y: auto !important;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
